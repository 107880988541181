import pragmatic_play from "./pragmatic_play.svg";
import aviatrix from "./aviatrix.svg";
import evo_gaming from "./evo_gaming.svg";
import amusnet from "./amusnet.svg";
import ezugi from "./ezugi.svg";
import pg from "./pg.svg";
import play_tech from "./play_tech.svg";
import thunderkick from "./thunderkick.svg";
import relax from "./relax.svg";
import onextwo from "./1x2.svg";
import playson from "./playson.svg";
import novomatic from "./novomatic.svg";
import bc from "./bc.svg";
import pay_cards from "./pay_cards.svg";
import pay_papara from "./pay_papara.svg";
import pay_paybol from "./pay_paybol.svg";
import pay_crypto from "./pay_crypto.svg";
import pay_havale from "./pay_havale.svg";
import pay_payfix from "./pay_payfix.svg";
import pay_pep from "./pay_pep.svg";
import pay_parazula from "./pay_parazula.svg";

export const partners = {
  pragmatic_play,
  aviatrix,
  evo_gaming,
  amusnet,
  ezugi,
  pg,
  play_tech,
  thunderkick,
  relax,
  onextwo,
  playson,
  novomatic,
  bc,
};

export const pay = {
  pay_cards,
  pay_papara,
  pay_paybol,
  pay_crypto,
  pay_havale,
  pay_payfix,
  pay_pep,
  pay_parazula,
};
