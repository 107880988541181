import React from "react";
import LandingTemplate from "../Layouts/LandingTemplate";
import banner from "./assets/banner.jpg";
import "./index.scss";
import { _t } from "../Common/components/InjectIntlContext";
import { partners, pay } from "./assets";
import BannerSlide from "../Landing/components/BannerSlide";
const AboutUs = () => {
  return (
    <LandingTemplate>
      <section className="about-us">
        <BannerSlide
          info={{
            title: _t("slide_2_title"),
            subtitle: _t("slide_1_text"),
            backgroundImg: banner,
            btnLabel: _t("get_started"),
          }}
        />
        <div className="container">
          <div className="about-us-description">
            <h2>{_t("about_us_section_title")}</h2>
            <p>{_t("about_us_section_text")}</p>
          </div>

          <div className="about-us-partners">
            <h3>{_t("ABOUT_US_PARTNERS_TITLE_1")}</h3>
            <div className="about-us-partners__list">
              <div>
                <img src={partners.pragmatic_play} alt="" />
                <img src={partners.aviatrix} alt="" />
                <img src={partners.evo_gaming} alt="" />
              </div>
              <div>
                <img src={partners.amusnet} alt="" />
                <img src={partners.play_tech} alt="" />
                <img src={partners.ezugi} alt="" />
                <img src={partners.pg} alt="" />
              </div>
              <div>
                <img src={partners.thunderkick} alt="" />
                <img src={partners.relax} alt="" />
                <img src={partners.onextwo} alt="" />
                <img src={partners.playson} alt="" />
                <img src={partners.novomatic} alt="" />
                <img src={partners.bc} alt="" />
              </div>
            </div>
          </div>
          <div className="about-us-partners about-us-partners--pay">
            <h3>{_t("ABOUT_US_PARTNERS_TITLE_2")}</h3>
            <div className="about-us-partners__list">
              <div>
                <img src={pay.pay_cards} alt="" />
                <img src={pay.pay_paybol} alt="" />
                <img src={pay.pay_papara} alt="" />
              </div>
              <div>
                <img src={pay.pay_crypto} alt="" />
                <img src={pay.pay_havale} alt="" />
                <img src={pay.pay_payfix} alt="" />
                <img src={pay.pay_pep} alt="" />
                <img src={pay.pay_parazula} alt="" />
              </div>
            </div>
          </div>
        </div>
      </section>
    </LandingTemplate>
  );
};

export default AboutUs;
