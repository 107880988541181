import React from "react";
import classNames from "classnames";
import { Link } from "react-router-dom";
import { APP_ROUTES } from "../../../system/constants/constantsUrl";

export type bannerSlideProps = {
  info: {
    className?: string;
    backgroundImg?: string;
    title?: string;
    subtitle?: string;
    btnLabel?: string;
    linkTo?: string;
    onClick?: () => any;
  };
};

const BannerSlide: React.FC<bannerSlideProps> = ({ info }) => {
  const {
    className,
    backgroundImg,
    title,
    subtitle,
    btnLabel,
    linkTo,
    onClick,
  } = info;

  return (
    <div className={classNames(className, "banner-slide")}>
      <div className="banner-slide__image">
        <img src={backgroundImg} alt="banner_background" />
      </div>
      <div className="container">
        <div className="banner-slide__content">
          <div
            className="banner-slide__title"
            dangerouslySetInnerHTML={{ __html: title }}
          ></div>
          <div
            className="banner-slide__sub-title"
            dangerouslySetInnerHTML={{ __html: subtitle }}
          ></div>
          <Link to={APP_ROUTES.registration} className="neon-btn">
            {btnLabel}
          </Link>
        </div>
      </div>
    </div>
  );
};

export default BannerSlide;
