import React from "react";
import {Faq} from "../../api";
import classNames from "classnames";

type QAProps = {
  faq: Faq;
};

export function QA({faq}: QAProps) {
  return (
    <div
      className="faq-item"
    >
      <p className="faq-item__title" dangerouslySetInnerHTML={{
        __html: faq.translations[0]?.question.replace(/<[^>]+>/g, ""),
      }}>

      </p>
      <div className="faq-item__text" dangerouslySetInnerHTML={{
        __html: faq.translations[0]?.answer.replace(/<[^>]+>/g, ""),
      }}>

      </div>
    </div>
  );
}
