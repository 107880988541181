import React from "react";
import { Link } from "react-router-dom";
import { _t } from "../../Common/components/InjectIntlContext";

export const PostPreview = ({ post }) => {
  return (
    <Link to={`/news-content/${post.url}`} className="news-preview">
      <span className="news-preview__img">
        <img src={`${post.host}${post.options_parsed.image}`} alt="" />
      </span>
      <span className="news-preview__title">{post.caption}</span>
      <span className="news-preview__read">{_t("read_more")}</span>
    </Link>
  );
};
