import React from "react";
import LandingTemplate from "../../Layouts/LandingTemplate";
import { useNewsList } from "../apiHooks";
import { getLocale } from "../../../system/helpers/localstorageHelper";
import { Spin } from "antd";
import { PostPreview } from "../components/PostPreview";
import img from "../../../assets/images/news-img.jpg";

const NewsList = () => {
  const locale = getLocale();
  const { data, isLoading } = useNewsList({
    language: locale,
  });
  return (
    <LandingTemplate>
      <section className="custom-section news-section">
        <Spin spinning={isLoading}>
          <div className="news-grid-section">
            <div className="container">
              <div className="news-grid">
                {data?.news?.map((post) => (
                  <PostPreview post={post} key={post.id} />
                ))}
              </div>
            </div>
          </div>
        </Spin>
      </section>
    </LandingTemplate>
  );
};

export default NewsList;
