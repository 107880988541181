import React, { useState } from "react";
import MainLayout from "../../../../../../modules/Layouts/MainLayouts";
import { PageTemplate } from "../../../../../../modules/Layouts/PageTemplate";
import { _t } from "../../../../../../modules/Common/components/InjectIntlContext";
import { useGetFrodersList } from "../../hooks";
import { Button, Pagination, Spin } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import { APP_ROUTES } from "../../../../../../system/constants/constantsUrl";
import { FrodersListTable } from "./components";
import { FiltersWidget } from "../../components";

const FrodersList = () => {
  const [page, setPage] = useState(1);
  const [order, setOrder] = useState("DESC");
  const [filters, setFilters] = useState({});
  const handleSubmit = (values) => {
    setFilters(values);
  };

  const { data, isLoading } = useGetFrodersList({ page, order, ...filters });
  const history = useHistory();
  return (
    <MainLayout>
      <PageTemplate title={_t("fraudsters")}>
        <Button
          size="large"
          shape="round"
          type="primary"
          icon={<PlusOutlined />}
          style={{ marginBottom: "20px" }}
          onClick={() => history.push(APP_ROUTES.manage.fraudsters.create)}
        >
          {_t("add_fraudsters")}
        </Button>
        <FiltersWidget onSubmit={handleSubmit} initialValues={filters} isSubmitting={isLoading}/>
        <Spin spinning={isLoading}>
          {data && (
            <>
              <FrodersListTable data={data.list} />
              <Pagination
                current={data.pagination.current_page}
                defaultCurrent={1}
                total={data.pagination.total}
                onChange={setPage}
                hideOnSinglePage
                showSizeChanger={false}
                pageSize={data.pagination.per_page || 100}
                className="froders-list-pagination"
              />
            </>
          )}
        </Spin>
      </PageTemplate>
    </MainLayout>
  );
};

export default FrodersList;
