import React from "react";

export const PostView = ({ post }) => {
  return (
    <div className="news-content">
      <div className="news-content__img">
        <img alt="" src={`${post.host}${post.options_parsed.image}`} />
      </div>
      <div className="news-content__content">
        <h2 className="news-content__title">{post.caption}</h2>
        <div
          className="news-content__text"
          dangerouslySetInnerHTML={{ __html: post.text }}
        ></div>
      </div>
    </div>
  );
};
